<template>
  <v-icon>
    <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5625 0.914062C0.679688 0.796875 0.84375 0.796875 0.960938 0.914062L5.88281 5.8125C5.97656 5.92969 5.97656 6.09375 5.88281 6.21094L0.960938 11.1094C0.84375 11.2266 0.679688 11.2266 0.5625 11.1094L0.09375 10.6641C0 10.5469 0 10.3594 0.09375 10.2656L4.33594 6L0.09375 1.75781C0 1.66406 0 1.47656 0.09375 1.35938L0.5625 0.914062Z"
        fill="#050F2D"
      />
    </svg>
  </v-icon>
</template>
