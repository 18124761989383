<script setup lang="ts">
import { type PropType, ref, onMounted, watch, computed } from 'vue'
import { AssessmentService } from '@/services/assessment.service'
import PreviewContent from './PreviewContent.vue'
import { useAssessmentStore } from '@/stores/assessment'
import ActionsButtonContent from './ActionsButtonContent.vue'
import AssessmentHeaderDescription from './AssessmentHeaderDescription.vue'
import type { AssessmentV1 } from '@/types/assessment.model'
import MoveToFolderCustomToast from './MoveToFolderCustomToast.vue'
import { useFoldersStore } from '@/stores/folders'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { useSearchStore } from '@/stores/search'
import { useModalStore } from '@/stores/modal'

const props = defineProps({
  assessment: {
    type: Object as PropType<AssessmentV1>,
    required: true
  },
  index: {
    type: Number,
    required: true
  },
  isFavorited: {
    type: Boolean,
    required: true,
    default: false
  }
})
const showToastNotification = ref(false)
const modalStore = useModalStore()
const expansionPanel = ref<any>(null)
const featureFlagStore = useFeatureFlagsStore()
// Iframe
const assessmentStore = useAssessmentStore()

const handleCloseNotification = () => {
  showToastNotification.value = false
}
const foldersStore = useFoldersStore()
const searchStore = useSearchStore()
const assessmentExpanded = computed(() => searchStore.lastFullScreenSearchAssessmentId)
const assessmentMovedToFolder = computed(() => foldersStore.getMoveToFolderShowToastNotification)
const moveToFolderOrigin = computed(() => foldersStore.getMoveToFolderOrigin)
const folderActived = computed(() => foldersStore.getActiveFolderId)
const administerAssessmentEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.ADMINISTER_ASSESSMENT_FLAG]
)
watch(
  () => assessmentMovedToFolder.value,
  (showToast) => {
    if (
      showToast &&
      moveToFolderOrigin.value === 'assessmentCard' &&
      expansionPanel.value &&
      expansionPanel.value.groupItem.isSelected.value &&
      folderActived.value === null
    ) {
      showToastNotification.value = showToast
      foldersStore.setMoveToFolderToastNotification(false)
    }
  }
)
const isModalStandardsOpened = computed(() => modalStore.getListOpenedModals)

const vClickOutside = {
  mounted: (el: any) => {
    el.clickOutsideEvent = (event: any) => {
      if (
        !el.contains(event.target) &&
        assessmentExpanded.value === props.index.toString() &&
        isModalStandardsOpened.value.length === 0
      ) {
        searchStore.updateLastFullScreenSearchAssessmentId(null)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el: any) => {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}

onMounted(() => {
  assessmentStore.setShowFullScreenButton(true)
  assessmentStore.setShowDescription(false)
})
</script>

<template>
  <v-expansion-panel ref="expansionPanel" v-click-outside :value="index" selected-class="panel">
    <v-expansion-panel-title
      class="panel-title"
      tabindex="-1"
      data-test="expandable-assessment"
      color="#FFFFFF"
    >
      <v-sheet width="96%" height="100%">
        <AssessmentHeaderDescription
          class="assessmentHeader"
          :index="index"
          :assessment="assessment"
          :isFavorited="isFavorited"
          :avatarSize="34"
        />
      </v-sheet>
    </v-expansion-panel-title>
    <v-expansion-panel-text class="expanded-panel">
      <h4 class="assessment-type-title" data-test="assessment-type-title">
        {{ AssessmentService.getAssessmentTypeLabel(assessment.assessment_type) }} |
        <span style="font-weight: 400"><strong>ID:</strong> {{ assessment.assessment_id }}</span>
      </h4>
      <div class="header">
        <h3 class="header__preview">Preview</h3>
        <MoveToFolderCustomToast
          :toastNotification="showToastNotification"
          :customPosition="
            (assessment.assessment_type === 'flex' || assessment.assessment_type === 'ibx') &&
            administerAssessmentEnabled
          "
          @closeNotification="handleCloseNotification"
        />
        <ActionsButtonContent :assessment="assessment" :isFavorited="isFavorited" />
      </div>
      <div class="assessment-preview-container">
        <PreviewContent :assessment="assessment" />
      </div>
    </v-expansion-panel-text>
  </v-expansion-panel>
</template>

<style scoped>
.assessment-type-title {
  padding-left: 24px;
  margin-top: 20px;
  color: #6a78a1;
  font-family: 'Cerebri Sans';
  font-size: 10px;
  font-weight: 700;
  line-height: 12.7px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.header__preview {
  flex: 1 1 100px;
  font-size: 16px;
  color: #202020;
  line-height: 20.32px;
  font-weight: 400;
}

.assessment-preview-container {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 24px 24px;
}

.expanded-panel {
  min-height: 150px;
  & :deep(.v-expansion-panel-text__wrapper) {
    padding-right: 0;
  }
  border-top: 2px solid #e0e1e6;
}
.panel {
  background-color: white;
  opacity: 0;
}

.panel-title {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #008272;
  }
}

.v-expansion-panel :deep(.v-expansion-panel-title--active) .v-expansion-panel-title__overlay {
  opacity: 0;
}

.v-expansion-panel:has(.v-expansion-panel-title--active) {
  box-shadow: 0px 1px 5px 0px #9297a6;
  -webkit-box-shadow: 0px 1px 5px 0px #9297a6;
  -moz-box-shadow: 0px 1px 5px 0px #9297a6;
}

.assessmentType {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.header {
  position: relative;
  margin: 10px 24px 0px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

:deep(.v-expansion-panel-title) {
  padding: 16px 18px;
}

:deep(.v-expansion-panel-title):focus-visible > .v-expansion-panel-title__overlay {
  background-color: transparent;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}

.panel-title:focus-within {
  box-shadow: 0 0 0 2px #2b87ff inset;
}

@media screen and (max-width: 350px) {
  .header {
    margin: 10px 12px 0px;
  }
  .assessment-preview-container {
    margin: 10px 12px 24px;
  }
  .assessment-type-title {
    padding-left: 12px;
  }
  .header__preview {
    flex: 1 1 100px;
  }
}
</style>
