import { defineStore } from 'pinia'
import { apiService } from '@/services/api-service'
import type { FavoriteAssessment, FavoriteAssessments } from '@/types/assessment.model'

export const useFavoriteStore = defineStore('favorites', {
  state: (): FavoriteAssessments => ({
    favorite_assessments: []
  }),
  getters: {
    currentFavoriteAssessments: (state) => state.favorite_assessments
  },
  actions: {
    async getFavoriteAssessments() {
      const favoriteAssessments = (await apiService.getFavoriteAssessments()).data
      this.favorite_assessments = [...favoriteAssessments]
      return this.favorite_assessments
    },

    async toggleFavorite(assessmentGuid: string) {
      const wasMarkedAsFavorite = this.favorite_assessments.includes(assessmentGuid) ? false : true
      const favoriteAssessment: FavoriteAssessment = {
        assessment_guid: assessmentGuid,
        favorite: wasMarkedAsFavorite
      }
      this.favorite_assessments = [
        ...(await apiService.updateFavoriteAssessment(favoriteAssessment)).data
      ]
    }
  }
})
