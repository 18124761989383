<template>
  <v-icon size="15">
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 3.25C0 1.88281 1.09375 0.75 2.5 0.75H7.65625C8.39844 0.75 9.10156 1.0625 9.64844 1.60938L11.2891 3.25H17.5C18.8672 3.25 20 4.38281 20 5.75V15.75C20 17.1562 18.8672 18.25 17.5 18.25H2.5C1.09375 18.25 0 17.1562 0 15.75V3.25ZM2.5 2.625C2.14844 2.625 1.875 2.9375 1.875 3.25V15.75C1.875 16.1016 2.14844 16.375 2.5 16.375H17.5C17.8125 16.375 18.125 16.1016 18.125 15.75V5.75C18.125 5.4375 17.8125 5.125 17.5 5.125H11.1719C10.7812 5.125 10.3516 4.96875 10.0781 4.69531L8.32031 2.89844C8.125 2.74219 7.89062 2.625 7.65625 2.625H2.5Z"
        fill="#008272"
      />
    </svg>
  </v-icon>
</template>
