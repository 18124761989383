<script setup lang="ts">
import { mdiPlus, mdiInformationSlabCircle } from '@mdi/js'
import { useSearchStore } from '@/stores/search'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useFoldersStore } from '@/stores/folders'
import Folder from './Folder.vue'
import CustomInput from './CustomInput.vue'
import { MAX_FOLDERS } from '@/util/folder.constants'
import InfoModal from './modals/InfoModal.vue'
import { useModalStore } from '@/stores/modal'
import { useSpinnerStore } from '@/stores/spinner'
import { ResultType } from '@/types/filters.model'
import { share } from 'pinia-shared-state'

const searchStore = useSearchStore()
const foldersStore = useFoldersStore()
const spinnerStore = useSpinnerStore()
const modalStore = useModalStore()
const isFolderBeingCreated = ref(false)
const allResultsRef = ref<any>(null)
const favoritesRef = ref<any>(null)
const draftsRef = ref<any>(null)
const foldersToDisplay = computed(() => foldersStore.getFolders)
const isLessThanFifteenFolders = computed(() => foldersStore.getFolders.length < MAX_FOLDERS)
const folderNameDuplicated = computed(() => foldersStore.folderNameDuplicated)
const folderNameDuplicatedMessage = ref('')
const getFolders = () => {
  return foldersToDisplay.value.sort((a, b) => a.folderName.localeCompare(b.folderName))
}

const total = computed(() =>
  spinnerStore.requestsPending === 0 ? searchStore.searchResults.hitCount : -1
)
const handleNewFolder = () => {
  isFolderBeingCreated.value = true
}
const handleEnterPressed = (folderNameInput: string) => {
  foldersStore.validateFolderNameDuplicated(folderNameInput)

  if (folderNameDuplicated.value === '') {
    folderNameInput.trim().length > 0 && foldersStore.addFolder(folderNameInput)
  }

  isFolderBeingCreated.value = false
}
const handleCloseInput = () => {
  isFolderBeingCreated.value = false
}

const getAriaLabel = (isSelected: boolean, folderName: string) => {
  return isSelected && total.value > -1
    ? `Folder ${folderName} (${total.value} ${total.value > 1 ? 'Results' : 'Result'})`
    : `Folder ${folderName}`
}

const handleSelectionChange = (newValue: boolean, resultType: ResultType) => {
  if (newValue) {
    searchStore.updateResultType(resultType)
    foldersStore.setActiveFolder(null)
  }
}

watch(
  () => foldersToDisplay.value,
  (newValue, oldValue) => {
    if (newValue.length < oldValue.length) {
      allResultsRef.value?.activate()
    }
  }
)

watch(
  () => folderNameDuplicated.value,
  (newValue) => {
    if (newValue.length > 0) {
      folderNameDuplicatedMessage.value = `The folder name you chose, ${newValue} , already exists. Please choose a different name.`
      modalStore.openModal('FolderDuplicated', {
        modalOpened: true
      })
      foldersStore.resetFolderNameDuplicated()
    }
  }
)

watch(
  () => allResultsRef.value?.isSelected,
  (newValue) => {
    handleSelectionChange(newValue, ResultType.ALL_RESULTS)
  }
)
watch(
  () => favoritesRef.value?.isSelected,
  (newValue) => {
    handleSelectionChange(newValue, ResultType.FAVORITES)
  }
)
watch(
  () => draftsRef.value?.isSelected,
  (newValue) => {
    handleSelectionChange(newValue, ResultType.DRAFTS)
  }
)
onMounted(() => {
  if (allResultsRef.value && !searchStore.getFavoriteStatusEnabled) {
    allResultsRef.value.activate()
  } else if (searchStore.getFavoriteStatusEnabled) {
    favoritesRef.value.activate()
  }
  const { unshare } = share('folders', foldersStore, { initialize: true })
  onUnmounted(() => {
    unshare()
  })
})
</script>

<template>
  <v-card variant="elevated" max-width="238" height="94vh">
    <v-list
      :mandatory="true"
      role="list"
      class="px-0"
      rounded="lg"
      :border="true"
      height="95vh"
      bg-color="#ECEDF1"
      :lines="false"
      density="compact"
      nav
    >
      <v-list-item
        ref="allResultsRef"
        key="allResults-key"
        role="listitem"
        data-test="inbox-assessments-results"
        :ripple="true"
        base-color="#050f2d"
        value="allResults"
        active-class="v-list-item--active__results"
        class="pl-2"
        :aria-label="getAriaLabel(allResultsRef?.isSelected, 'All Results')"
        tabindex="0"
      >
        <template v-slot:default="{ isActive }">
          <v-list-item-title class="pl-2" :aria-hidden="true">{{
            isActive && total > -1 ? `All Results (${total})` : 'All Results'
          }}</v-list-item-title>
        </template>
      </v-list-item>
      <v-list-item
        ref="draftsRef"
        key="drafts-key"
        role="listitem"
        data-test="drafts-folder"
        class="pl-2"
        :ripple="false"
        base-color="#050f2d"
        value="drafts"
        active-class="v-list-item--active__results"
        :aria-label="getAriaLabel(draftsRef?.isSelected, 'Drafts')"
        tabindex="0"
      >
        <template v-slot:default="{ isActive }">
          <v-list-item-title class="pl-2" :aria-hidden="true">{{
            isActive && total > -1 ? `Drafts (${total})` : 'Drafts'
          }}</v-list-item-title>
        </template>
      </v-list-item>
      <v-list-item
        ref="favoritesRef"
        key="favorites-key"
        role="listitem"
        data-test="favorites-folder"
        class="pl-2"
        :ripple="false"
        base-color="#050f2d"
        value="favorites"
        active-class="v-list-item--active__results"
        :aria-label="getAriaLabel(favoritesRef?.isSelected, 'Favorites')"
        tabindex="0"
      >
        <template v-slot:default="{ isActive }">
          <v-list-item-title class="pl-2" :aria-hidden="true">{{
            isActive && total > -1 ? `Favorites (${total})` : 'Favorites'
          }}</v-list-item-title>
        </template>
      </v-list-item>
      <v-divider
        length="90%"
        :thickness="1"
        class="border-opacity-100 mb-2 mt-2 ml-3"
        color="#80C0B8"
        :aria-hidden="true"
      ></v-divider>
      <TransitionGroup name="fade" tag="div">
        <div v-for="folder in getFolders()" :key="folder.folderId">
          <Folder :folder="folder" />
        </div>
      </TransitionGroup>

      <CustomInput
        v-if="isFolderBeingCreated"
        @enterPressed="handleEnterPressed"
        @closeInput="handleCloseInput"
      />
      <v-list-item
        v-if="isLessThanFifteenFolders"
        data-test="inbox-assessments-new-folder"
        base-color="#008272"
        value="newFolder"
        :title="'New Folder'"
        @click="handleNewFolder"
      >
        <template v-slot:prepend>
          <v-icon :icon="mdiPlus" class="ml-2"></v-icon>
        </template>
      </v-list-item>
      <v-list-item
        v-else
        class="v-list-item__disclaimer"
        subtitle="To add another folder, you must delete one."
      >
        <template v-slot:prepend>
          <v-icon :icon="mdiInformationSlabCircle" class="ml-2"></v-icon>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
  <InfoModal
    :uniqueId="'FolderDuplicated'"
    :message="folderNameDuplicatedMessage"
    :title="'Duplicate Folder Name'"
    :labelButton="'OK'"
  />
  <InfoModal
    :uniqueId="'delete-folder'"
    labelButton="ok"
    title="Empty the Folder"
    message="Archive the assessments in this folder, then you’ll be able to delete the empty folder."
  />
</template>

<style scoped>
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

:deep(.v-list-item__prepend > .v-icon ~ .v-list-item__spacer) {
  width: 20px;
}
:deep(.v-list-item--nav .v-list-item-title) {
  font-family: 'Cerebri Sans Medium';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.v-list-item--active__results {
  background-color: #ffffff;
}
.v-list-item--active__results::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #008272;
}
:deep(.v-list-item--active > .v-list-item__overlay) {
  opacity: unset;
}
:deep(.v-list-item--variant-text .v-list-item__overlay) {
  background-color: transparent;
}

:deep(.v-list-item__prepend > .v-icon) {
  opacity: unset;
  color: #008272;
}
:deep(.v-list-item--nav .v-list-item-subtitle) {
  color: #202020;
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  opacity: unset;
}
:deep(.v-list-item__disclaimer .v-list-item__prepend) {
  position: relative;
  bottom: 15px;
  font-size: 14px;
}
</style>
