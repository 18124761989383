<script setup lang="ts"></script>

<template>
  <div class="no-assessment-container" data-test="no-assessment-container">
    <svg
      width="262"
      height="266"
      viewBox="0 0 262 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <g filter="url(#filter0_i_380_4012)">
        <path
          d="M117.126 247.901C177.72 247.901 226.841 199.294 226.841 139.335C226.841 79.3752 177.72 30.7686 117.126 30.7686C56.5327 30.7686 7.41187 79.3752 7.41187 139.335C7.41187 199.294 56.5327 247.901 117.126 247.901Z"
          fill="#C7CCC8"
        />
      </g>
      <mask
        id="mask0_380_4012"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="262"
        height="248"
      >
        <path
          d="M117.126 247.901C177.72 247.901 226.841 199.294 226.841 139.335C226.841 129.737 254.812 115.428 252.449 106.565C248.486 91.698 268.927 57.9273 259.479 46.2782C249.012 33.3726 195.959 20.0988 180.645 13.008C166.541 6.47678 137.494 0 120.892 0C107.378 0 89.9157 21.0933 77.9606 25.5155C63.675 30.7999 14.3964 20.4652 3.6459 30.7688C-6.92136 40.8966 9.43227 75.4279 3.6459 89.0542C-1.84321 101.981 7.41182 124.435 7.41182 139.335C7.41182 199.294 56.5327 247.901 117.126 247.901Z"
          fill="#C7CCC8"
        />
      </mask>
      <g mask="url(#mask0_380_4012)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M123.068 9.25586L250.943 46.4764L201.014 231.198L70.2349 189.44L123.068 9.25586Z"
          fill="#A2B2D6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M123.035 9.25586L250.91 46.4764L198.077 226.66L70.2017 189.44L123.035 9.25586Z"
          fill="white"
        />
        <path
          d="M115.709 179.214L131.813 170.336L141.977 186.842L174.68 163.431L188.573 197.528L115.94 181.187L115.709 179.214Z"
          fill="#906E47"
        />
        <path
          d="M193.589 191.248L183.029 172.21L160.16 183.094L147.884 167.311L126.104 182.436L109.076 165.634L95.7766 177.767L188.342 203.611L193.589 191.248Z"
          fill="#D5B848"
        />
        <path
          d="M193.589 191.248L183.029 172.21L160.16 183.094L147.884 167.311L126.104 182.436L109.076 165.634L95.7766 177.767"
          stroke="white"
          stroke-width="5.4147"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M83.3357 177.8L99.1427 169.021L104.918 177.175L119.009 170.731L127.853 182.206L154.484 172.605L169.07 191.807L197.087 187.73L190.85 209.036L83.3357 177.8Z"
          fill="#647681"
        />
        <path
          d="M126.335 62.4233L132.605 71.3339L147.95 64.2975L157.586 76.8249L186.56 66.3361L202.466 87.248L232.925 82.8091"
          stroke="white"
          stroke-width="5.8944"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M107.831 107.119L106.519 111.602L111.588 113.074L112.899 108.592L107.831 107.119Z"
          fill="#E3C954"
        />
        <path
          d="M105.716 114.327L104.404 118.81L109.473 120.282L110.785 115.8L105.716 114.327Z"
          fill="#949B7A"
        />
        <path
          d="M114.489 109.921L113.64 112.825L138.413 120.022L139.263 117.118L114.489 109.921Z"
          fill="#CACACA"
        />
        <path
          d="M112.373 117.123L111.523 120.027L150.553 131.366L151.403 128.462L112.373 117.123Z"
          fill="#CACACA"
        />
        <path
          d="M103.633 121.545L102.321 126.027L107.39 127.499L108.702 123.017L103.633 121.545Z"
          fill="#C18639"
        />
        <path
          d="M110.259 124.331L109.409 127.235L148.439 138.574L149.288 135.67L110.259 124.331Z"
          fill="#CACACA"
        />
        <path
          d="M162.369 122.984L161.057 127.466L166.126 128.938L167.438 124.456L162.369 122.984Z"
          fill="#AABED7"
        />
        <path
          d="M160.254 130.192L158.942 134.674L164.011 136.146L165.323 131.664L160.254 130.192Z"
          fill="#AC5B2E"
        />
        <path
          d="M169.028 125.786L168.178 128.69L192.952 135.887L193.802 132.983L169.028 125.786Z"
          fill="#CACACA"
        />
        <path
          d="M166.912 132.988L166.062 135.892L205.092 147.23L205.941 144.326L166.912 132.988Z"
          fill="#CACACA"
        />
        <path
          d="M158.139 137.4L156.828 141.882L161.897 143.355L163.208 138.873L158.139 137.4Z"
          fill="#638592"
        />
        <path
          d="M164.797 140.196L163.947 143.1L202.977 154.438L203.827 151.534L164.797 140.196Z"
          fill="#CACACA"
        />
        <path
          opacity="0.6"
          d="M117.755 70.5117C135.443 75.7726 143.363 32.535 168.278 41.2811C195.404 50.7835 166.892 100.794 212.663 114.374C215.567 115.229 218.339 115.952 220.979 116.544L218.9 123.022L111.914 91.6209L117.755 70.5117Z"
          fill="#AABED7"
        />
        <path
          opacity="0.5"
          d="M122.375 54.6631C134.222 58.1813 138.578 68.3413 141.152 74.7529C145.145 84.6499 157.487 92.048 169.895 83.6964C189.761 70.3141 203.522 76.2654 211.244 92.4425C214.313 98.8542 218.9 103.227 224.345 104.937L218.702 122.298L111.749 90.6999L122.375 54.6631Z"
          fill="#D5BA39"
        />
        <path
          opacity="0.4"
          d="M220.814 115.624C218.174 115.032 215.402 114.308 212.498 113.453C190.487 106.91 185.636 91.9826 183.557 77.1536C179.399 78.2387 174.845 80.3759 169.928 83.6639C157.52 92.0155 145.178 84.6174 141.185 74.7205C139.667 70.9721 137.555 65.9086 133.661 61.667C128.843 67.2238 123.959 71.2352 117.986 69.6569L111.749 90.7003L218.57 122.364L220.814 115.624Z"
          fill="#98B0C2"
        />
        <path
          d="M174.284 109.113L183.59 77.3507C182.006 66.2701 181.973 55.2552 177.485 47.8242L160.226 104.937L174.284 109.113Z"
          fill="#FFD121"
        />
        <path
          d="M183.59 77.3838L183.293 78.2716"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M182.468 81.0659L174.581 107.436"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="5.88 5.88"
        />
        <path
          d="M174.152 108.85L173.888 109.738"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M202.796 79.7837L202.532 80.7043"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M201.74 83.335L192.896 112.96"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="5.61 5.61"
        />
        <path
          d="M192.5 114.275L192.203 115.196"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M175.967 52.4932L160.985 102.57"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="5.94 5.94"
        />
        <path
          d="M160.589 103.984L160.292 104.872"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M133.496 62.4888L133.199 63.4094"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M132.374 66.2046L124.487 92.5746"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-dasharray="5.88 5.88"
        />
        <path
          d="M124.058 93.9556L123.794 94.8762"
          stroke="white"
          stroke-width="2.88"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M195.503 104.279C199.595 108.093 205.073 111.25 212.498 113.486C215.402 114.341 218.174 115.064 220.814 115.656"
          stroke="#8C83DD"
          stroke-width="3.84"
          stroke-miterlimit="10"
        />
        <path
          d="M117.59 69.5908C135.278 74.8517 143.198 31.6141 168.113 40.3602C190.817 48.3173 174.548 84.6829 195.503 104.28"
          stroke="#8C83DD"
          stroke-width="3.84"
          stroke-miterlimit="10"
        />
        <path
          d="M224.213 105.397C218.669 103.688 213.884 99.1502 210.782 92.6399C206.525 83.6965 198.374 73.8652 183.689 77.6136L183.425 76.6601C195.569 73.5364 205.337 78.9288 211.673 92.2124C214.643 98.4597 219.197 102.8 224.477 104.444L224.213 105.397Z"
          fill="#647681"
        />
        <path
          d="M165.902 85.8666C167.222 85.3076 168.575 84.5843 169.928 83.6965C174.878 80.3756 179.432 78.2384 183.623 77.1533"
          stroke="white"
          stroke-width="3.0463"
          stroke-miterlimit="10"
        />
        <path
          d="M153.89 87.1163C153.23 86.919 152.57 86.6889 151.91 86.4258C146.96 84.3544 142.769 80.0799 140.723 74.9506C138.446 69.2623 134.189 58.7077 122.276 55.1567L122.573 54.2031C134.948 57.8857 139.337 68.7362 141.68 74.5889C143.66 79.4552 147.62 83.5324 152.339 85.5052C156.662 87.3136 161.282 87.2807 165.737 85.4394L166.133 86.3601C162.041 88.0041 157.85 88.2671 153.89 87.1163Z"
          fill="#647681"
        />
        <path
          d="M132.143 61.2389C132.407 60.3511 133.331 59.8579 134.222 60.1209C135.113 60.384 135.608 61.3046 135.344 62.1924C135.08 63.0802 134.156 63.5734 133.265 63.3103C132.374 63.0144 131.879 62.0938 132.143 61.2389Z"
          fill="#AC5B2E"
        />
        <path
          d="M133.133 63.7381C133.1 63.7381 133.133 63.7381 133.133 63.7381C131.978 63.4093 131.351 62.2256 131.681 61.0748C131.846 60.5158 132.209 60.0884 132.704 59.8253C133.199 59.5623 133.76 59.4965 134.321 59.6609C134.882 59.8253 135.311 60.187 135.575 60.6802C135.839 61.1734 135.905 61.7653 135.74 62.2913C135.443 63.4422 134.255 64.0669 133.133 63.7381ZM134.057 60.5487C133.76 60.45 133.43 60.4829 133.166 60.6473C132.902 60.8117 132.671 61.0419 132.605 61.3378C132.407 61.9625 132.77 62.6201 133.397 62.8174C134.024 63.0147 134.684 62.653 134.882 62.0283C134.981 61.7324 134.948 61.4036 134.783 61.1405C134.618 60.8446 134.354 60.6473 134.057 60.5487Z"
          fill="#AC5B2E"
        />
        <path
          d="M120.956 54.4005C121.22 53.5127 122.144 53.0195 123.035 53.2826C123.926 53.5456 124.421 54.4663 124.157 55.354C123.893 56.2418 122.969 56.735 122.078 56.472C121.187 56.2089 120.692 55.2554 120.956 54.4005Z"
          fill="white"
        />
        <path
          d="M121.946 56.8993C121.385 56.7349 120.923 56.3732 120.659 55.88C120.395 55.3868 120.329 54.7949 120.494 54.2689C120.824 53.1509 122.012 52.4933 123.134 52.8221C124.256 53.1509 124.916 54.3346 124.586 55.4854C124.421 56.0444 124.058 56.4718 123.563 56.7349C123.068 56.9979 122.474 57.0637 121.946 56.8993ZM120.956 54.4004L121.418 54.5319C121.319 54.8278 121.352 55.1566 121.517 55.4197C121.682 55.6827 121.913 55.9129 122.21 55.9786C122.507 56.0773 122.837 56.0444 123.101 55.88C123.365 55.7156 123.596 55.4854 123.662 55.1895C123.86 54.5648 123.497 53.9072 122.87 53.7099C122.243 53.5126 121.583 53.8743 121.385 54.499L120.956 54.4004Z"
          fill="#647681"
        />
        <path
          d="M181.973 76.8907C182.237 76.003 183.161 75.5098 184.052 75.7728C184.943 76.0358 185.438 76.9565 185.174 77.8443C184.91 78.732 183.986 79.2252 183.095 78.9622C182.204 78.6991 181.709 77.7785 181.973 76.8907Z"
          fill="white"
        />
        <path
          d="M182.963 79.4222C182.402 79.2578 181.94 78.8961 181.676 78.4029C181.412 77.9097 181.346 77.3179 181.511 76.7918C181.841 75.6739 183.029 75.0163 184.151 75.3451C185.273 75.6739 185.933 76.8576 185.603 78.0084C185.438 78.5673 185.075 78.9948 184.58 79.2578C184.085 79.5209 183.524 79.5866 182.963 79.4222ZM182.435 77.0548C182.336 77.3508 182.369 77.6796 182.534 77.9426C182.699 78.2057 182.93 78.4358 183.227 78.5016C183.524 78.6002 183.854 78.5673 184.118 78.4029C184.382 78.2385 184.613 78.0084 184.679 77.7125C184.877 77.0877 184.514 76.4301 183.887 76.2328C183.293 76.0684 182.633 76.4301 182.435 77.0548Z"
          fill="#647681"
        />
        <path
          d="M201.08 80.277C201.344 79.3892 202.268 78.896 203.159 79.159C204.05 79.4221 204.545 80.3427 204.281 81.2305C204.017 82.1183 203.093 82.6115 202.202 82.3484C201.311 82.0854 200.816 81.1318 201.08 80.277Z"
          fill="#647681"
        />
        <path
          d="M143.198 47.8243C143.462 46.9366 144.386 46.4434 145.277 46.7064C146.168 46.9694 146.663 47.8901 146.399 48.7779C146.135 49.6656 145.211 50.1588 144.32 49.8958C143.429 49.6327 142.934 48.7121 143.198 47.8243Z"
          fill="#8C83DD"
        />
        <path
          d="M116.633 69.2291C116.897 68.3413 117.821 67.8481 118.712 68.1112C119.603 68.3742 120.098 69.2949 119.834 70.1826C119.57 71.0704 118.646 71.5636 117.755 71.3006C116.864 71.0375 116.369 70.1169 116.633 69.2291Z"
          fill="white"
          stroke="#8C83DD"
          stroke-width="2.88"
          stroke-miterlimit="10"
        />
        <path
          d="M165.209 87.8723C164.219 87.4449 163.724 86.327 164.021 85.3077C164.186 84.7487 164.549 84.3213 165.044 84.0582C165.539 83.7952 166.133 83.7294 166.661 83.8938C166.76 83.9267 166.826 83.9596 166.925 83.9925C167.915 84.4199 168.41 85.5378 168.113 86.5571C167.783 87.6751 166.595 88.3327 165.44 88.0039C165.407 87.9381 165.308 87.9052 165.209 87.8723Z"
          fill="white"
        />
        <path
          d="M166.76 84.3871C167.519 84.7159 167.915 85.5708 167.684 86.3599C167.42 87.2477 166.496 87.7409 165.605 87.4778C165.539 87.4449 165.473 87.4449 165.407 87.4121C164.648 87.0833 164.252 86.2284 164.483 85.4392C164.747 84.5515 165.671 84.0583 166.562 84.3213C166.628 84.3542 166.694 84.3542 166.76 84.3871ZM167.156 83.5322C167.057 83.4993 166.958 83.4335 166.826 83.4007C165.44 83.0061 163.988 83.7623 163.559 85.1433C163.196 86.3928 163.79 87.7409 165.011 88.2669C165.11 88.2998 165.209 88.3656 165.341 88.3985C166.727 88.793 168.179 88.0368 168.608 86.6558C168.971 85.4064 168.344 84.0583 167.156 83.5322Z"
          fill="#647681"
        />
        <path
          d="M194.612 106.154C193.622 105.726 193.127 104.608 193.424 103.589C193.754 102.471 194.942 101.814 196.097 102.142C196.196 102.175 196.262 102.208 196.361 102.241C197.351 102.669 197.846 103.786 197.549 104.806C197.384 105.365 197.021 105.792 196.526 106.055C196.031 106.318 195.437 106.384 194.909 106.22C194.81 106.22 194.711 106.187 194.612 106.154Z"
          fill="white"
        />
        <path
          d="M196.196 102.669C196.955 102.998 197.351 103.853 197.12 104.642C196.856 105.529 195.932 106.023 195.041 105.76C194.975 105.727 194.909 105.727 194.843 105.694C194.084 105.365 193.688 104.51 193.919 103.721C194.183 102.833 195.107 102.34 195.998 102.603C196.031 102.636 196.13 102.636 196.196 102.669ZM196.559 101.814C196.46 101.781 196.361 101.715 196.229 101.682C194.843 101.288 193.391 102.044 192.962 103.425C192.599 104.675 193.193 106.023 194.414 106.549C194.513 106.582 194.612 106.647 194.744 106.68C196.13 107.075 197.582 106.319 198.011 104.938C198.374 103.688 197.747 102.34 196.559 101.814Z"
          fill="#8C83DD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4287 40.1964L145.706 33.4888L158.774 226.332L19.2827 233.204L11.4287 40.1964Z"
          fill="#A2B2D6"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.4287 40.1964L145.706 33.4888L155.21 222.715L20.9327 229.422L11.4287 40.1964Z"
          fill="white"
        />
        <path
          d="M100.067 151.068C99.4728 153.304 98.4168 155.343 96.9648 157.053L109.406 172.605C115.016 167.278 118.877 160.176 120.065 152.252L100.067 151.068Z"
          fill="#B4D8E7"
        />
        <path
          d="M87.1968 131.176C94.0278 132.228 99.4398 137.653 100.463 144.591L120.428 145.775C120.428 145.545 120.428 145.347 120.428 145.117C119.471 126.573 104.456 112.072 86.2068 111.349L87.1968 131.176Z"
          fill="#E2992F"
        />
        <path
          d="M72.4459 136.864C74.5249 134.3 77.3629 132.393 80.6959 131.538L79.6729 111.711C69.1129 113.191 60.2029 119.241 54.7249 127.658L72.4459 136.864Z"
          fill="#65ADBC"
        />
        <path
          d="M91.9158 161.13C90.0018 162.116 87.8568 162.708 85.5468 162.84C76.7358 163.3 69.2448 156.527 68.7828 147.748C68.6838 145.939 68.9808 144.262 69.4428 142.618L51.7217 133.379C49.7747 138.114 48.7517 143.276 49.0487 148.734C50.0387 168.364 66.8358 183.488 86.5368 182.469C93.1038 182.14 99.1758 180.036 104.291 176.649L91.9158 161.13Z"
          fill="#648CB0"
        />
        <path
          d="M37.8573 198.597L31.991 198.893L32.2546 204.081L38.121 203.785L37.8573 198.597Z"
          fill="#65ADBC"
        />
        <path
          d="M38.4511 210.401L32.5847 210.697L32.8484 215.885L38.7147 215.589L38.4511 210.401Z"
          fill="#648CB0"
        />
        <path
          d="M85.1869 196.223L39.9697 198.504L40.2334 203.693L85.4505 201.412L85.1869 196.223Z"
          fill="#CACACA"
        />
        <path
          d="M85.7807 208.028L40.5635 210.309L40.8271 215.497L86.0443 213.216L85.7807 208.028Z"
          fill="#CACACA"
        />
        <path
          d="M96.3334 195.671L90.467 195.967L90.7307 201.155L96.5971 200.859L96.3334 195.671Z"
          fill="#E2992F"
        />
        <path
          d="M96.9272 207.475L91.0608 207.771L91.3244 212.959L97.1908 212.663L96.9272 207.475Z"
          fill="#B4D8E7"
        />
        <path
          d="M142.441 193.328L98.4434 195.547L98.707 200.736L142.705 198.516L142.441 193.328Z"
          fill="#CACACA"
        />
        <path
          d="M143.035 205.132L99.0371 207.352L99.3008 212.54L143.299 210.32L143.035 205.132Z"
          fill="#CACACA"
        />
        <path
          d="M134.892 45.1084L24.4529 50.6797L24.7499 56.5248L135.189 50.9535L134.892 45.1084Z"
          fill="#58595B"
        />
        <path
          d="M135.815 62.7637L25.376 68.335L25.6713 74.1472L136.111 68.576L135.815 62.7637Z"
          fill="#CACACA"
        />
        <path
          d="M136.707 80.4532L26.2678 86.0244L26.5632 91.8367L137.002 86.2654L136.707 80.4532Z"
          fill="#CACACA"
        />
        <path
          d="M108.049 90.7552L26.7109 94.8584L27.0063 100.671L108.344 96.5674L108.049 90.7552Z"
          fill="#CACACA"
        />
        <path
          d="M136.245 71.6094L25.8054 77.1807L26.1008 82.9929L136.54 77.4217L136.245 71.6094Z"
          fill="#CACACA"
        />
        <path
          d="M135.365 53.9186L44.4697 58.5039L44.7651 64.3162L135.661 59.7308L135.365 53.9186Z"
          fill="#CACACA"
        />
      </g>
      <path
        d="M208.535 184.483L202.654 194.561L161.157 170.021L167.038 159.943L208.535 184.483Z"
        fill="#272525"
      />
      <path
        opacity="0.51"
        d="M170.459 139.216C169.822 114.029 149.03 94.0849 124.018 94.6701C99.0056 95.2553 79.2454 116.148 79.882 141.335C80.5186 166.522 101.311 186.466 126.323 185.881C151.335 185.296 171.095 164.403 170.459 139.216Z"
        fill="#F2F2F2"
      />
      <path
        opacity="0.18"
        d="M143.091 175.563C134.979 178.127 126.568 178.841 118.127 177.946C110.033 177.079 101.376 174.824 94.4986 170.282C91.6437 168.398 89.0264 166.154 86.8464 163.51C93.0015 173.263 102.822 180.696 114.877 183.54C133.252 187.854 151.596 180.287 161.929 165.802C156.444 170.209 149.799 173.44 143.091 175.563Z"
        fill="black"
      />
      <path
        d="M245.912 211.379L243.382 215.736C241.719 218.592 238.031 219.574 235.181 217.873L192.657 192.709C192.185 192.419 192.034 191.832 192.287 191.344L199.897 178.326C200.187 177.856 200.776 177.707 201.238 177.969L243.762 203.133C246.639 204.825 247.576 208.523 245.912 211.379Z"
        fill="#272525"
      />
      <path
        d="M208.76 182.02L200.178 196.762L198.42 195.709L207.002 180.968L208.76 182.02Z"
        fill="#F7D77F"
      />
      <path
        d="M212.934 184.587L204.352 199.329L202.576 198.313L211.186 183.561L212.934 184.587Z"
        fill="#F7D77F"
      />
      <path
        d="M167.097 165.091C153.569 188.326 123.734 196.081 100.633 182.389C77.5052 168.705 69.7148 138.661 83.2532 115.453C96.7818 92.2187 126.617 84.4629 149.717 98.1559C172.818 111.849 180.626 141.857 167.097 165.091ZM87.0471 117.735C74.7432 138.851 81.8465 166.145 102.865 178.565C123.883 190.985 150.972 183.935 163.276 162.819C175.58 141.703 168.477 114.409 147.459 101.989C126.468 89.5596 99.351 96.6193 87.0471 117.735Z"
        fill="#272525"
      />
      <path
        opacity="0.61"
        d="M129.164 101.076C122.651 99.5825 115.56 100.65 109.534 103.478C104.346 105.914 99.3277 110.43 97.9893 116.23C97.7822 117.1 98.8734 118.086 99.5929 117.278C103.052 113.533 106.721 110.203 111.415 108.096C116.757 105.666 122.499 105.784 128.26 105.957C130.971 105.997 131.972 101.724 129.164 101.076Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_i_380_4012"
          x="7.41187"
          y="30.7686"
          width="219.429"
          height="221.132"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.714276 0 0 0 0 0.738878 0 0 0 0 0.719196 0 0 0 0.1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_380_4012" />
        </filter>
      </defs>
    </svg>
    <h1>No Assessments Found</h1>
    <h2>Try modifying your search criteria.</h2>
  </div>
</template>

<style scoped lang="scss">
.no-assessment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1 {
  margin-top: 20px;
  font-family: Cerebri Sans;
  font-size: 36px;
  font-weight: 500;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
}

h2 {
  margin-top: 10px;
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
