<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue'
import { assessmentService } from '@/services/assessment.service'
import type { ModalData } from '@/types'
import type { Standard } from '@/types/standards.model'
import { onMounted, ref } from 'vue'
import { useModalStore } from '@/stores/modal'
import LoadingSpinner from '@/components/LoadingSpinner.vue'

const props = defineProps({
  assessmentGuid: {
    type: String,
    required: true
  },
  uniqueId: {
    type: String,
    required: true
  }
})

const standards = ref<Standard[]>()
const modalStore = useModalStore()

const modalData: ModalData = {
  modalHeader: {
    title: 'Standards in this Assessment',
    includeCloseBtnInHeader: true
  },
  modalSize: {
    minWidth: 320,
    maxWidth: 600
  }
}

onMounted(async () => {
  standards.value = (await assessmentService.getStandards(props.assessmentGuid))!.data as Standard[]
})
</script>

<template>
  <BaseModal :modalData="modalData" :uniqueId="uniqueId">
    <ul class="standard-menu">
      <!-- TODO: Renable once AM-823 is completed. -->
      <li v-for="standard in standards" :key="standard.guid" class="standard-menu-item">
        <h4>{{ standard.code }}</h4>
        <p>{{ standard.description }}</p>
      </li>
    </ul>
    <LoadingSpinner v-if="modalStore.isModalOpen(uniqueId)" />
  </BaseModal>
</template>

<style scoped>
.standard-section {
  padding: 10px 20px;
}

.standards-link-bank {
  padding: 10px 20px;
  color: #0049ff;
  text-decoration: none;
}

.standards-link {
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  cursor: pointer;
  padding: 0;
  text-transform: capitalize;
}

.standard-menu {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.standard-menu-item {
  font-family: Cerebri Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: justify;
  list-style-position: inside;
  overflow: hidden;
  white-space: wrap;
  & h4 {
    display: inline-block;
  }
}
</style>
