<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue'
import type { ModalData } from '@/types'
const props = defineProps({
  uniqueId: {
    type: String,
    required: true
  },
  labelButton: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  }
})
const modalData: ModalData = {
  modalHeader: {
    title: props.title,
    includeCloseBtnInHeader: false
  },
  modalSize: {
    minHeight: 188,
    maxWidth: 600,
    minWidth: 320
  },
  modalActions: {
    buttons: {
      okButton: {
        label: props.labelButton,
        bgColor: '#008272',
        color: 'white',
        size: 'large',
        customHeight: '36',
        customWidth: '53'
      }
    }
  }
}
</script>
<template>
  <BaseModal :modalData="modalData" :uniqueId="uniqueId">
    <p class="info-modal-font">{{ message }}</p>
  </BaseModal>
</template>
<style lang="scss">
.info-modal-font {
  font-family: 'Cerebri Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #050f2d;
}
</style>
