<script lang="ts" setup>
import { type PropType, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import NoAssessmentContent from './NoAssessmentContent.vue'
import { AssessmentService } from '@/services/assessment.service'
import type { AssessmentV1 } from '@/types/assessment.model'

defineProps({
  assessment: {
    type: Object as PropType<AssessmentV1>,
    required: true
  }
})
const iframe = ref<HTMLIFrameElement | null>(null)
const isIframeLoaded = ref(false)
const isIframeError = ref(false)
const { smAndDown } = useDisplay()

const unwatch = watch(
  () => iframe.value,
  () => {
    if (iframe.value && !isIframeLoaded.value) {
      isIframeLoaded.value = true
      unwatch()

      iframe.value.addEventListener('load', () => {
        const iframeDocument =
          iframe.value?.contentDocument || iframe.value?.contentWindow?.document
        if (iframeDocument) {
          const iframeContent = iframeDocument.body.innerHTML
          const regex = /Error: \[GET\]/
          const hasError = regex.test(iframeContent)
          if (hasError) {
            isIframeError.value = true
          }
        }
      })
    }
  }
)
</script>

<template>
  <template
    v-if="
      isIframeError ||
      (isIframeLoaded && (assessment.assessment_type !== 'ibx' || assessment.field_count === 0))
    "
  >
    <NoAssessmentContent
      :assessmentType="assessment.assessment_type"
      :assessmentId="assessment.assessment_id.toString()"
    />
  </template>
  <iframe
    v-else
    ref="iframe"
    tabindex="1"
    :class="[!isIframeLoaded ? 'hidden-iframe' : '', 'assessment-preview']"
    :src="AssessmentService.getAssessmentPreviewUrl(assessment)"
    :height="smAndDown ? '400' : '350'"
  ></iframe>
</template>

<style lang="scss" scoped>
.assessment-preview {
  width: 100%;
  border: 1px solid #e0e1e6;
  border-radius: 5px;
}

.hidden-iframe {
  display: none;
}
</style>
