<script setup lang="ts">
import { useSpinnerStore } from '@/stores/spinner'
import { computed, ref, watch } from 'vue'
const spinnerStore = useSpinnerStore()
const loading = computed(() => spinnerStore.shouldShowSpinner())
const showSpinner = ref(false)
watch(
  () => loading.value,
  (newVal) => {
    showSpinner.value = newVal
  }
)
</script>
<template>
  <v-overlay
    v-model="showSpinner"
    class="align-center justify-center"
    scroll-strategy="block"
    contained
  >
    <v-progress-circular :indeterminate="true" color="primary" :size="50"></v-progress-circular>
  </v-overlay>
</template>
<style scoped></style>
