<script setup lang="ts">
import { mdiOpenInNew } from '@mdi/js'
import LeavingModal from './modals/LeavingModal.vue'
import { useModalStore } from '@/stores/modal'
import { ASSESSMENT_TYPES } from '@/util/assessment-types.constants'
import InstanceService from '@/services/instance.service'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { computed } from 'vue'
import { useFeatureFlagsStore } from '@/stores/feature-flag'

const isEditDeleteNoContentAssessmentEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.EDIT_DELETE_NO_CONTENT_ASSESSMENT]
)
const props = defineProps({
  assessmentType: {
    type: String,
    required: true
  },
  assessmentId: {
    type: String,
    required: true
  }
})
const featureFlagStore = useFeatureFlagsStore()
const assessmentCardViewEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.ASSESSMENT_CARD_VIEW_FLAG]
)
const modalStore = useModalStore()
const getCallbackFunction = () => {
  switch (props.assessmentType) {
    case ASSESSMENT_TYPES.LEGACY_ITEM_BANK:
      InstanceService.navigateToLegacyManualManageAssesment(props.assessmentId)
      break
    case ASSESSMENT_TYPES.FLEXIBLE:
      if (isEditDeleteNoContentAssessmentEnabled.value) {
        InstanceService.navigateToFlexibleManageAssesment(props.assessmentId)
      }
      break
    case ASSESSMENT_TYPES.MANUAL_HYBRID:
      InstanceService.navigateToLegacyManualManageAssesment(props.assessmentId)
      break
    case ASSESSMENT_TYPES.ITEM_BANK:
      if (isEditDeleteNoContentAssessmentEnabled.value) {
        InstanceService.navigateToNewItemBank(props.assessmentId)
      }
      break
    default:
      break
  }
}
</script>

<template>
  <div class="no-assessment-content">
    <p class="paragraph">
      This type of assessment provides no preview.
      <a
        v-if="assessmentCardViewEnabled"
        data-test="no-assessment-content-link"
        @click="
          modalStore.openModal(`Leaving-${assessmentType}-${assessmentId}`, {
            modalOpened: true
          })
        "
        >View <span> or </span> Manage Assessment
        <v-icon class="ml-1" aria-hidden="false"> {{ mdiOpenInNew }} </v-icon>
      </a>
    </p>
  </div>
  <LeavingModal
    :uniqueId="`Leaving-${assessmentType}-${assessmentId}`"
    :callbackFunction="getCallbackFunction"
  />
</template>
<style scoped>
.no-assessment-content {
  width: 100%;
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #2b3963;
  background-color: #eaecef;
  border: 1px solid #e0e1e6;
  border-radius: 5px;
  padding: 12px;
}

.paragraph {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  padding: 14px 0;
  & > a {
    color: #00685b;
    text-decoration: underline;
    cursor: pointer;
  }
}
:deep(.v-btn__append) {
  margin-left: 2px;
}
:deep(.v-btn__content) {
  border-bottom: 1px solid;
}

:deep(.v-btn--variant-text) .v-btn__overlay {
  background-color: transparent;
}
</style>
