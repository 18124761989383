import { apiService } from '@/services/api-service'
import type { UserAssesesmentPermissions } from '@/types/user.model'
import { defineStore } from 'pinia'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { useFeatureFlagsStore } from './feature-flag'
import { computed } from 'vue'

export interface Permissions {
  userAssessmentPermissions: UserAssesesmentPermissions
}

export const useAssessmentPermissions = defineStore('assessment-permissions', {
  state: (): Permissions => ({
    userAssessmentPermissions: {
      administer: false,
      edit: false,
      view: false,
      share: false,
      delete: false,
      assessment_guid: null
    }
  }),
  getters: {
    currentPermissions: (state) => state.userAssessmentPermissions
  },
  actions: {
    isAssessmentGranteePermissionFlagEnabled: () => {
      const assessmentGranteePermissionFlag = computed(
        () => useFeatureFlagsStore().flags[FEATURE_FLAGS.ASSESSMENT_GRANTEE_PERMISSIONS]
      )
      return assessmentGranteePermissionFlag.value
    },
    async getUserAssessmentPermissions(assessmentGuid: string) {
      const userAssessmentPermission = (
        await apiService.getUserAssessmentPermissions(assessmentGuid)
      ).data
      const permissions: UserAssesesmentPermissions = {
        administer: userAssessmentPermission.administer,
        edit: userAssessmentPermission.edit,
        view: userAssessmentPermission.view,
        share: userAssessmentPermission.share,
        delete: userAssessmentPermission.delete,
        assessment_guid: userAssessmentPermission.assessment_guid
      }
      this.userAssessmentPermissions = permissions
      return this.userAssessmentPermissions
    },
    getAssessmentId() {
      return this.currentPermissions.assessment_guid
    },
    canUserView() {
      return this.isAssessmentGranteePermissionFlagEnabled() && this.currentPermissions.view
    },
    canUserShare() {
      return this.isAssessmentGranteePermissionFlagEnabled() && this.currentPermissions.share
    },
    canUserAdminister() {
      return this.isAssessmentGranteePermissionFlagEnabled() && this.currentPermissions.administer
    },
    canUserEdit() {
      return this.isAssessmentGranteePermissionFlagEnabled() && this.currentPermissions.edit
    },
    async getUserPermissionsWhenFlagIsEnabled(assessmentGuid: string) {
      if (this.isAssessmentGranteePermissionFlagEnabled()) {
        await this.getUserAssessmentPermissions(assessmentGuid)
      }
    }
  }
})
