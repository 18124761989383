<script setup lang="ts">
import { ref, reactive, onMounted, watch, computed, nextTick } from 'vue'
import IconCaretDown from './icons/IconCaretDown.vue'
import { useFilterStore } from '@/stores/filters'
import * as _ from 'lodash'
import type { ComponentPublicInstance } from 'vue'

const props = defineProps({
  label: String,
  id: String,
  reset: Boolean,
  items: Object
})

const activeItems = reactive(new Map())
const filterStore = useFilterStore()
const selectedItemsSize = computed(() => activeItems.size)
const initPage = ref(false)
const preSelectedFilters = computed(() => filterStore.currentPreSelectedFilters)
const open = ref(false)
const dropdownRef = ref<HTMLElement | null>(null)
const buttonRef = ref<ComponentPublicInstance | null>(null)
const emit = defineEmits(['clickOption', 'update:preselectedFilters', 'update:reset'])

const getButtonLabel = () => {
  switch (props.label) {
    case 'AssessmentType':
      return 'Type'
    case 'Question':
      return 'Number of Questions'
    case 'Statuse':
      return 'Status'
    default:
      return props.label
  }
}

const toggleOptions = () => {
  open.value = !open.value
}

const handleFocusOut = (event: FocusEvent) => {
  const relatedTarget = event.relatedTarget as HTMLElement | null

  // Check if focus moved outside the dropdown
  if (dropdownRef.value && !dropdownRef.value.contains(relatedTarget)) {
    open.value = false
  }
}

const toggleSelectedItem = (item: any) => {
  if (!activeItems.has(item.value)) {
    activeItems.set(item.value, item)
  } else {
    activeItems.delete(item.value)
  }
  initPage.value = false
  emit('clickOption', activeItems)
}

const onPressEscape = async () => {
  open.value = false

  await nextTick()

  const nativeButton = buttonRef.value?.$el as HTMLElement | null
  if (nativeButton) {
    _.delay(() => {
      nativeButton.focus() // adding 20ms of delay because nvda is not able to detect the change in open flag
    }, 20)
  }
}

const clearOptions = () => {
  activeItems.clear()
  emit('update:preselectedFilters', [])
  if (props?.label) {
    filterStore.updatePresetFilters(props.label, [])
  }
}

watch(
  () => props.reset,
  (newVal) => {
    if (newVal) {
      activeItems.clear()
      emit('update:reset', false) // Emit the event with the new value
    }
  }
)

watch(
  () => props.items,
  (newVal) => {
    let getLabel = props.label === 'AssessmentType' ? 'assessmentType' : props.label?.toLowerCase()
    if (
      newVal?.length > 0 &&
      preSelectedFilters?.value &&
      preSelectedFilters?.value[`${getLabel}s`] &&
      preSelectedFilters?.value[`${getLabel}s`].length > 0
    ) {
      preSelectedFilters.value[`${getLabel}s`].forEach((idPreSelected: any) => {
        const element = newVal?.find((element: any) => element.value === idPreSelected)
        if (element?.value && !activeItems.has(element.value)) {
          activeItems.set(element.value, element)
        }
      })
    }
  },
  { immediate: true }
)

watch(
  () => [activeItems.size, initPage.value],
  (newVal) => {
    const newArray = [...activeItems.values()].map((item) => item.value)
    if (!newVal[1] && !props.reset && props.label) {
      filterStore.updatePresetFilters(props.label, newArray)
    }
  }
)
onMounted(() => {
  initPage.value = true
})
</script>
<template>
  <div
    v-if="items!.length > 0"
    ref="dropdownRef"
    tabindex="-1"
    class="select-container"
    @focusout="handleFocusOut"
    @keydown.esc="onPressEscape"
  >
    <v-badge
      :class="{ withoutBadge: selectedItemsSize === 0 }"
      color="#80c0b8"
      :content="selectedItemsSize"
      aria-live="polite"
      :aria-label="
        `${getButtonLabel()}, ${selectedItemsSize.toString()}` +
        (selectedItemsSize > 1 ? ' Filters' : ' Filter')
      "
      :data-test="`${label?.toLowerCase()}-badge`"
    >
      <v-btn
        :id="id"
        ref="buttonRef"
        :class="['rounded', `${selectedItemsSize === 0 ? 'select-btn' : 'select-btn-filled'}`]"
        variant="text"
        :aria-expanded="open"
        :aria-label="
          `${getButtonLabel()}, ${selectedItemsSize.toString()}` +
          (selectedItemsSize > 1 ? ' Filters' : ' Filter')
        "
        :data-test="`filter-${label}`"
        @click="toggleOptions"
      >
        {{ getButtonLabel() }}
        <template v-slot:append>
          <IconCaretDown />
        </template>
      </v-btn>
    </v-badge>

    <div v-if="open" :id="`${id}div`" tabindex="-1" class="filter-container">
      <ul
        :data-test="`ul-${getButtonLabel()?.toString().replace(/\s+/g, '-')}`"
        class="list-items v-field--variant-solo"
      >
        <li v-for="item in items" :key="item.value" class="item">
          <v-checkbox
            :data-test="`filter-${item.label.toString().replace(/\s+/g, '-').toLowerCase()}`"
            class="checkbox-option"
            color="#008272"
            :label="item.label"
            :hide-details="true"
            :aria-checked="activeItems.has(item.value)"
            :model-value="activeItems.has(item.value)"
            @click="toggleSelectedItem(item)"
            @keydown.enter="toggleSelectedItem(item)"
          />
        </li>
      </ul>
      <v-divider aria-hidden="true" />
      <div>
        <v-btn
          :data-test="`clear-${getButtonLabel()?.toString().replace(/\s+/g, '-')}`"
          variant="plain"
          class="btn-clear"
          @click="clearOptions()"
          >Clear</v-btn
        >
      </div>
    </div>
  </div>
</template>
<style scoped>
:deep(label).v-label {
  width: 100%;
  font-size: 12px;
  font-family: 'Cerebri Sans';
  font-weight: 400;
  line-height: 17.78px;
}

:deep(.v-selection-control--focus-visible) .v-selection-control__input::before {
  border: 2px solid #2b87ff;
  border-radius: 0;
  background-color: transparent;
  opacity: 1;
  width: 54%;
  height: 54%;
  position: absolute;
  top: 9px;
  left: 9px;
}

.select-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.rounded {
  border-radius: 50px !important;
  text-transform: capitalize;
}

.select-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 1px solid #7e8494;
  box-sizing: border-box;
  color: #050f2d;
  min-width: 100px;
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  background-color: #ffffff;
}

.select-btn:hover {
  background-color: #ecedf1;
  border-color: #7e8494;
}

.select-btn-filled {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #050f2d;
  min-width: 100px;
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  background-color: #e5f2f1;
  border: 1px solid #80c0b8;
}

.select-btn-filled:hover {
  border: 1px solid #006c90;
}

.select-btn::after {
  border: none;
}
.select-btn:focus-visible,
.select-btn-filled:focus-visible {
  border: 2px solid #2b87ff;
}

.select-btn:focus-visible {
  background-color: #ecedf1;
}

:deep(.v-btn--variant-text) .v-btn__overlay {
  background-color: transparent;
}

.filter-container {
  position: absolute;
  top: 100%;
  transform: translateY(10px);
  z-index: 99;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e0e1e6;
  box-shadow: -1px 4px 4px 0px rgba(5, 15, 45, 0.2);
  -webkit-box-shadow: -1px 4px 4px 0px rgba(5, 15, 45, 0.2);
  -moz-box-shadow: -1px 4px 4px 0px rgba(5, 15, 45, 0.2);
  overflow: hidden;
  width: max-content;
}

.list-items {
  border-radius: inherit;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow: auto;
}

.item {
  font-family: 'Cerebri Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  color: #050f2d;
  list-style: none;
  flex: 0 0 auto; /* Adjust width to fit within the flex container */
}

.btn-clear {
  margin: 10px;
  font-size: 14px;
  text-transform: none;
  color: #050f2d;
  font-family: 'Cerebri Sans';
  font-weight: 400;
  line-height: 17.78px;
}

ul.v-field--variant-solo {
  box-shadow: none;
}

.v-input--density-default {
  --v-input-control-height: 0px;
  --v-input-padding-top: 0px;
}

.checkbox-option > label {
  display: inline-block;
  width: 100%;
}

:deep(.withoutBadge) .v-badge__wrapper .v-badge__badge {
  background-color: transparent !important;
  color: transparent !important;
}
:deep(.v-badge__badge) {
  height: 18px;
  min-width: 18px;
  left: calc(100% - 18px) !important;
  bottom: calc(100% - 12px) !important;
  border-radius: 50%;
  font-family: 'Cerebri Sans Medium';
  color: #202020 !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 10px;
}

:deep(.v-selection-control__wrapper),
:deep(.v-selection-control__input) {
  width: fit-content;
  margin-right: 2px;
}
</style>
