import { defineStore } from 'pinia'
const delayDuration = 500

export const useSpinnerStore = defineStore('spinner-store', {
  state: () => ({
    loading: false,
    loadingAuth: false,
    requestsPending: 0,
    showSpinnerTimer: null as ReturnType<typeof setTimeout> | null // Correctly type the timer
  }),
  actions: {
    show() {
      this.loading = true
    },
    hide() {
      this.loading = false
    },
    pending() {
      if (this.requestsPending === 0) {
        // Start a timer to show the spinner after 500 milliseconds
        this.showSpinnerTimer = setTimeout(() => {
          if (this.requestsPending > 0) {
            this.show()
          }
        }, delayDuration)
      }
      this.requestsPending++
    },
    done() {
      if (this.requestsPending >= 1) {
        this.requestsPending--
      }

      if (this.requestsPending <= 0) {
        // Clear the timer if it's still pending
        if (this.showSpinnerTimer) {
          clearTimeout(this.showSpinnerTimer)
          this.showSpinnerTimer = null
        }
        this.hide()
      }
    },
    shouldShowSpinner() {
      return (this.loading === true && this.requestsPending > 0) || this.loadingAuth === true
    }
  }
})
