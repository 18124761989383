<script setup lang="ts">
import { ref } from 'vue'
import type { SortConditionValue } from '@/types'
import { SORT_CONDITION_VALUES } from '@/types'
import { useSearchStore } from '@/stores/search'
import { mdiMenuDown } from '@mdi/js'
import { computed } from 'vue'
import { useDisplay } from 'vuetify'
import CustomSortedDropdownIcon from './icons/CustomSortedDropdownIcon.vue'
const { xs } = useDisplay()

const searchStore = useSearchStore()
const sortConditionSelected = computed(() => searchStore.getSortConditionSelected)
const selectedSort = ref<SortConditionValue>(
  SORT_CONDITION_VALUES.filter((scv: SortConditionValue) => scv.default)[0]
) // Default to the first sort condition
const sortConditionValue = SORT_CONDITION_VALUES.filter((scv: SortConditionValue) => scv.default)[0]
const buttonLabel = ref<string>(
  sortConditionSelected.value !== ''
    ? sortConditionSelected.value
    : `${sortConditionValue.name} ${sortConditionValue.secondName || ''}`
)

const selectSortOption = (sortCondition: SortConditionValue) => {
  searchStore.updateLastFullScreenSearchAssessmentId(null)
  selectedSort.value = sortCondition
  buttonLabel.value = `${sortCondition.name} ${sortCondition.secondName || ''}`
  searchStore.updateSelectedSortConditions(sortCondition.sortCondition)
  searchStore.updateSortConditionSelected(`${sortCondition.name} ${sortCondition.secondName || ''}`)
}
const handleClick = () => {
  searchStore.lastFullScreenSearchAssessmentId &&
    searchStore.updateLastFullScreenSearchAssessmentId(null)
}
</script>

<template>
  <div class="sort-button">
    <div>
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            :append-icon="mdiMenuDown"
            v-bind="props"
            density="comfortable"
            variant="text"
            class="btn-menu ghost-btn"
            data-test="sort-dropdown-button-test"
            @click="handleClick"
          >
            <template v-slot:prepend>
              <CustomSortedDropdownIcon />
            </template>
            <span :class="{ 'truncate-text': xs }"> Sort {{ buttonLabel }}</span>
          </v-btn>
        </template>
        <v-list role="menu">
          <v-list-item
            v-for="(sortCondition, index) in SORT_CONDITION_VALUES"
            :key="sortCondition.name"
            :value="sortCondition.name"
            :data-test="`condition_${index}_test`"
            role="menuitem"
            @click="selectSortOption(sortCondition)"
          >
            <v-list-item-title>
              {{ sortCondition.name }}
              <span> {{ sortCondition.secondName }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </div>
</template>
<style scoped>
:deep(.v-btn__prepend) {
  margin: 0 2.5px 0 0;
  font-size: 17.112px;
}
.truncate-text {
  display: inline-block;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sort-button {
  display: flex;
  justify-content: flex-end;
}

.btn-menu {
  font-family: 'Cerebri Sans Medium';
  font-size: 14px;
  line-height: 17.78px;
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
  padding: 0;
}
:deep(.v-list-item-title) {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  line-height: 25px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0;
  & span {
    font-family: 'Cerebri Sans';
    font-size: 12px;
    line-height: 25px;
    font-weight: 400;
    text-transform: none;
    color: #7e8494;
    letter-spacing: 0;
  }
}
</style>
