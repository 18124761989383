export interface SortCondition {
  field: string
  order: string
}

export interface SortConditionValue {
  name: string
  secondName?: string
  sortCondition: SortCondition
  default?: boolean
}

const sortConditionValues: SortConditionValue[] = [
  {
    name: 'Title',
    secondName: '(A-Z)',
    sortCondition: {
      field: 'title.keyword',
      order: 'asc'
    }
  },
  {
    name: 'Title',
    secondName: '(Z-A)',
    sortCondition: {
      field: 'title.keyword',
      order: 'desc'
    }
  },
  {
    name: 'Relevance',
    sortCondition: {
      field: '',
      order: ''
    },
    default: true
  },
  {
    name: 'Date Created',
    sortCondition: {
      field: 'created_at',
      order: 'desc'
    }
  },
  {
    name: 'Number of Questions',
    secondName: '(low-high)',
    sortCondition: {
      field: 'field_count',
      order: 'asc'
    }
  },
  {
    name: 'Number of Questions',
    secondName: '(high-low)',
    sortCondition: {
      field: 'field_count',
      order: 'desc'
    }
  }

  /*
  {
    name: 'Number of Standards (Most first)',
    sortCondition: {
      field: 'standards_count',
      order: 'desc'
    }
  },
  {
    name: 'Number of Standards (Fewest first)',
    sortCondition: {
      field: 'standards_count',
      order: 'asc'
    }
  }
  {
    name: 'Number of Question Types (Most first)',
    sortCondition: {
      field: 'field_types_count',
      order: 'desc'
    }
  },
  {
    name: 'Number of Question Types (Fewest first)',
    sortCondition: {
      field: 'field_types_count',
      order: 'asc'
    }
  } */
]

export const SORT_CONDITION_VALUES = sortConditionValues
