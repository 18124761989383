<script lang="ts" setup>
import { reactive, onMounted } from 'vue'
import { mdiCloseCircle } from '@mdi/js'
const props = defineProps({
  filterValues: {
    type: Array<{ id: string; name: string; variant: string; label: string }>,
    required: true,
    deep: true
  },
  label: {
    type: String,
    required: true
  }
})

const pressedBtnMap = reactive(new Map())

const emit = defineEmits(['selectedValues'])

const selectValue = (filter: any) => {
  emit('selectedValues', filter)
  pressedBtnMap.set(filter.id, !pressedBtnMap.get(filter.id))
}

onMounted(() => {
  props.filterValues.forEach((filter) => {
    pressedBtnMap.set(filter.id, false)
  })
})
</script>
<template>
  <v-row v-if="filterValues.length > 0" class="pb-3 pl-2 values-filter-title-container" no-gutters>
    <v-col class="values-filter-title-container__title" cols="12">
      {{ label === 'grades' ? 'Grades (Optional)' : 'Subjects (Optional)' }}
    </v-col>
  </v-row>
  <v-row v-if="filterValues.length > 0" class="valuesFilter mb-6" justify="start" no-gutters>
    <v-col cols="12">
      <v-btn
        v-for="filter in filterValues"
        :key="filter.id"
        role="button"
        :aria-label="filter.name"
        :aria-pressed="pressedBtnMap.has(filter.id) ? pressedBtnMap.get(filter.id) : false"
        :data-test="`${filter.id}-filter-button-test-${label.toLocaleLowerCase()}`"
        class="valuesFilter__button ma-1"
        :append-icon="filter.variant === 'outlined' ? mdiCloseCircle : ''"
        rounded="xl"
        :variant="filter.variant as 'outlined' | 'flat'"
        size="small"
        @click="selectValue(filter)"
      >
        {{ filter.name }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<style scoped>
.valuesFilter__button {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  background-color: #e0e1e6;
  color: #050f2d;
  text-transform: capitalize;
}
.values-filter-title-container__title {
  font-size: 14px;
  font-weight: 400;
}
</style>
