<script setup lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue'
import { useAuthStore } from '@/stores/auth'
import { useModalStore } from '@/stores/modal'
import type { ModalData } from '@/types'
import { ref, watch } from 'vue'
const props = defineProps({
  callbackFunction: {
    type: Function,
    required: true
  },
  uniqueId: {
    type: String,
    required: true
  }
})
const hideDialog = ref(false)
const authStore = useAuthStore()
const modalStore = useModalStore()
let modalData: ModalData = {
  modalHeader: {
    title: 'Leaving Current Page',
    includeCloseBtnInHeader: true
  },
  modalSize: {
    minHeight: 214,
    maxWidth: 600,
    minWidth: 320
  },
  modalActions: {
    buttons: {
      okButton: {
        label: 'ok',
        bgColor: '#008272',
        color: 'white',
        callback: () => {
          if (hideDialog.value) {
            authStore.updateHiddenModal('LeavingModal', hideDialog.value)
          }
          return props.callbackFunction()
        },
        size: 'large'
      },
      cancelButton: {
        label: 'Cancel',
        bgColor: '#ffffff',
        color: '#050F2D'
      }
    }
  }
}
watch(
  () => modalStore.isModalOpen(props.uniqueId),
  (isOpen: boolean) => {
    if (isOpen && authStore.isModalHidden('LeavingModal')) {
      modalStore.closeModal(props.uniqueId)
      return props.callbackFunction()
    }
  }
)
</script>
<template>
  <BaseModal v-if="modalStore.isModalOpen(uniqueId)" :modalData="modalData" :uniqueId="uniqueId">
    <p class="leaving-modal-font">
      We need to take you to a different page in DnA to complete this task
    </p>
    <v-checkbox
      v-model="hideDialog"
      hide-details
      class="checkbox"
      color="#008272"
      :aria-checked="hideDialog"
      @keydown.enter="hideDialog = !hideDialog"
    >
      <template v-slot:label>
        <span class="leaving-modal-font" data-test="hide-message-checkbox">
          Hide this message next time.
        </span>
      </template>
    </v-checkbox>
  </BaseModal>
</template>
<style lang="scss">
.leaving-modal-font {
  font-family: 'Cerebri Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 17.78px;
  color: #050f2d;
}

.checkbox {
  font-family: 'Cerebri Sans';
  font-weight: 400;
  font-size: 14px;
  line-height: 17.78px;
  color: #050f2d;
  margin-left: -10px;
}
</style>
