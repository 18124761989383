<script setup lang="ts">
import { ref } from 'vue'
import CustomFolderIcon from './icons/CustomFolderIcon.vue'
import { useDisplay } from 'vuetify'

const props = defineProps({
  folderNameProp: {
    type: String,
    required: false
  }
})
const display = useDisplay()
const folderName = ref<string | undefined>(props.folderNameProp ? props.folderNameProp : '')
const emit = defineEmits(['enterPressed', 'closeInput'])
const onEnterPress = () => {
  emit('enterPressed', folderName.value)
}
const onSpacePress = (event: any) => {
  // Allow space character to be inserted
  event.preventDefault()
  event.target.value += ' '
}
const handleKeydown = (event: any) => {
  if (event.key === 'Escape' || event.key === 'Esc') {
    emit('closeInput')
  }
}

const onBlur = () => {
  emit('closeInput')
}
</script>
<template>
  <v-list-item
    value="0"
    class="side-v-list"
    :ripple="false"
    color="#ffffff"
    @keydown="handleKeydown"
  >
    <template v-slot:prepend>
      <div :class="{ 'pl-2': display.width.value > 599 }">
        <CustomFolderIcon />
      </div>
    </template>
    <v-text-field
      v-model="folderName"
      :autofocus="true"
      variant="outlined"
      density="compact"
      type="input"
      base-color="#7E8494"
      color="#7E8494"
      class="text-field__folder pl-6"
      hide-details="auto"
      data-test="custom-input"
      :maxlength="15"
      @blur="onBlur"
      @keydown.enter="onEnterPress"
      @keydown.space.stop.prevent="onSpacePress"
    />
  </v-list-item>
</template>

<style scoped>
.side-v-list {
  background-color: #ffffff;
}

.side-v-list::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #008272;
}

.text-field__folder {
  color: #050f2d;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
