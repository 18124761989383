<script setup lang="ts">
import InstanceService from '@/services/instance.service'
import IconAngleLeft from './icons/IconAngleLeft.vue'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const router = useRouter()
const route = useRoute()
const color = ref('#FFFFFF')
const btnLabel = computed(() => {
  if (route.name === 'assessment') {
    return 'Back to Assessments'
  } else if (route.name === 'home') {
    return 'Home'
  } else {
    return 'Back'
  }
})

const navigateTo = () => {
  if (document.referrer === '' && route.name === 'assessment') {
    router.push({ name: 'home' })
  } else if (router.currentRoute.value.name === 'home') {
    InstanceService.navigateToOldAMExperience()
  } else {
    router.back()
  }
}
</script>
<template>
  <v-btn class="action-btn" variant="text" data-test="back-button" @click="navigateTo">
    <template v-slot:prepend>
      <IconAngleLeft :color="color" :size="6" />
    </template>
    <span>{{ btnLabel }}</span>
  </v-btn>
</template>
<style scoped>
.v-btn--variant-text:deep(.v-btn__overlay) {
  background-color: transparent;
}
:deep(.v-btn__prepend) {
  margin-right: 3px;
}
.action-btn {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  text-transform: capitalize;
  letter-spacing: 0;
}
.action-btn.v-btn:focus-visible {
  border: 1px solid #ffffff;
  outline: none !important;
}
.action-btn.v-btn::after {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
</style>
