<script setup lang="ts">
import { useModalStore } from '@/stores/modal'
import type { ModalData } from '@/types'
import type { PropType } from 'vue'

const modalStore = useModalStore()

defineProps({
  modalData: {
    type: Object as PropType<ModalData>,
    required: true
  },
  uniqueId: {
    type: String,
    required: true
  }
})

const formatStringWithDashes = (str: string, typeElement: string) => {
  return `${str.toLowerCase().trim().replace(/\s+/g, '-')}-${typeElement}`
}
</script>
<template>
  <v-dialog
    :key="uniqueId"
    v-model="modalStore.getComponentFromKey(uniqueId!).modalOpened"
    class="base-dialog"
    :min-width="modalData!.modalSize?.minWidth ? modalData!.modalSize?.minWidth : 200"
    :max-width="modalData!.modalSize?.maxWidth ? modalData!.modalSize?.maxWidth : 700"
    :min-height="modalData!.modalSize?.minHeight ? modalData!.modalSize?.minHeight : 432"
    :max-height="modalData!.modalSize?.maxHeight ? modalData!.modalSize?.maxHeight : 600"
    scrollable
  >
    <v-card>
      <v-card-title>
        <div
          class="modal-header"
          :data-test="formatStringWithDashes(modalData!.modalHeader.title, 'modal') || 'base-modal'"
        >
          <h1 class="header">
            {{ modalData!.modalHeader.title || '' }}
          </h1>
          <v-btn
            v-if="modalData!.modalHeader.includeCloseBtnInHeader"
            data-test="closeXButton"
            class="close-modal-btn"
            density="compact"
            icon="$close"
            variant="text"
            color="#050F2D"
            @click="modalStore.closeModal(uniqueId)"
          />
        </div>
      </v-card-title>
      <v-card-text class="card-text">
        <!-- Use the passed property in the dialog content -->
        <section class="standard-section">
          <slot></slot>
        </section>
      </v-card-text>
      <v-card-actions class="actions">
        <v-btn
          v-if="modalData!.modalActions?.buttons?.cancelButton"
          size="x-large"
          :height="modalData!.modalActions.buttons.cancelButton?.customHeight"
          :min-width="modalData!.modalActions.buttons.cancelButton?.customWidth"
          class="actions__cancel-btn"
          variant="text"
          :data-test="
            formatStringWithDashes(modalData!.modalHeader.title, 'cancel-button') ||
            'base-modal-cancel-button'
          "
          :style="{
            'background-color': modalData!.modalActions.buttons.cancelButton?.bgColor || 'unset',
            color: modalData!.modalActions.buttons.cancelButton?.color || '#000000'
          }"
          @click="modalStore.closeModal(uniqueId)"
          >{{ modalData!.modalActions.buttons.cancelButton.label }}</v-btn
        >
        <v-btn
          v-if="modalData!.modalActions?.buttons?.okButton"
          :disabled="modalData!.modalActions.buttons.okButton.disabled"
          :size="modalData!.modalActions.buttons.okButton.size || 'x-large'"
          variant="flat"
          :data-test="
            formatStringWithDashes(modalData!.modalHeader.title, 'ok-button') ||
            'base-modal-ok-button'
          "
          :height="modalData!.modalActions.buttons.okButton?.customHeight"
          :min-width="modalData!.modalActions.buttons.okButton?.customWidth"
          :class="{
            'ok-button-padding': true,
            actions__textTransformCapitalize: modalData!.modalActions.buttons.okButton.textTransform
          }"
          :style="{
            'background-color': modalData!.modalActions.buttons.okButton.bgColor || 'unset',
            color: modalData!.modalActions.buttons.okButton.color || '#000000'
          }"
          @click="
            modalData?.modalActions.buttons.okButton.callback
              ? modalData?.modalActions.buttons.okButton.callback()
              : modalStore.closeModal(uniqueId)
          "
          >{{ modalData!.modalActions.buttons.okButton.label }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.standard-section {
  font-size: 16px;
  padding: 0 20px 20px;
  letter-spacing: normal;
}

.ok-button-padding {
  padding: 0 16px;
}

.close-modal-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-modal-btn :deep(.v-btn__content .v-icon) {
  width: 16px;
  height: 16px;
  color: #495b8f;
}

:deep(.v-btn__content .v-icon) {
  height: 28px;
  width: 28px;
}
.actions__textTransformCapitalize {
  text-transform: capitalize;
}
.v-card-title,
.v-card-text,
.v-card-actions {
  padding: 0 !important;
  & .v-btn {
    font-size: 14px;
  }
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:deep(.v-btn--icon.v-btn--density-default) {
  height: 24px;
}

.modal-header {
  padding: 20px;
}
.actions {
  padding: 0 20px !important;
}

.actions {
  justify-content: flex-end;
  padding-bottom: 20px !important;
}
.actions__cancel-btn {
  text-transform: capitalize;
}

.header {
  font-family: 'Cerebri Sans Semi Bold';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: left;
  line-height: 25.4px;
  text-wrap: wrap;
  margin-right: 5px;
}
.v-dialog :deep(.v-overlay__content .v-card) {
  border-radius: 8px;
}
</style>
