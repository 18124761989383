<script setup lang="ts">
import { useFoldersStore } from '@/stores/folders'
import { mdiCheckCircleOutline, mdiClose } from '@mdi/js'
import { computed, nextTick, onBeforeUnmount, ref, watch } from 'vue'

const props = defineProps({
  toastNotification: {
    type: Boolean,
    required: true
  },
  customPosition: {
    type: Boolean,
    required: false,
    default: false
  }
})

const emit = defineEmits(['closeNotification'])
const showNotification = ref(false)
const foldersStore = useFoldersStore()
const messageContent = computed(() => foldersStore.getMoveToFolderToastMessage)
const snackbarRef = ref<HTMLDivElement | null>(null)

watch(
  () => props.toastNotification,
  async (newVal) => {
    if (newVal) {
      showNotification.value = newVal
      await nextTick()
      if (snackbarRef.value) {
        snackbarRef.value.focus()
      }
    }
  }
)
watch(
  () => showNotification.value,
  (newVal) => {
    foldersStore.setToastIsBeingShown(newVal)
    if (!newVal) {
      emit('closeNotification')
    }
  }
)
onBeforeUnmount(() => {
  showNotification.value = false
  emit('closeNotification')
})
</script>
<template>
  <v-snackbar
    v-model="showNotification"
    class="snackbar-position"
    :class="{ custom__position: customPosition }"
    location="right"
    color="#202020"
    min-height="36"
    :absolute="true"
    :timeout="10000"
    contained
  >
    <div ref="snackbarRef" tabindex="0">
      <v-icon size="x-small" color="green" class="mr-1 mb-1" :icon="mdiCheckCircleOutline" />
      {{ messageContent }}
    </div>
    <template v-slot:actions>
      <v-btn
        color="#FFFFFF"
        variant="text"
        size="x-small"
        :icon="mdiClose"
        @click="showNotification = false"
      />
    </template>
  </v-snackbar>
</template>

<style scoped>
:deep(.v-snackbar__content) {
  padding: 8px 16px;
}

.snackbar-position {
  padding: 0px;
  margin-top: 1.5rem;
  color: #ffffff;
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.78px;
  text-align: center;
}
.custom__position {
  right: 17%;
  margin-top: 2.3rem;
}
</style>
