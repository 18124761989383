import { apiService } from '@/services/api-service'
import { defineStore } from 'pinia'
import type { preSelectedFilters } from '@/types/filters.model'

interface FiltersState {
  preSelectedFilters: {
    grades: string[]
    assessmentTypes: string[]
    tags: string[]
    subjects: string[]
    standards: string[]
    fieldTypes: string[]
    questions: string[]
    [key: string]: string[]
  }
}

export const useFilterStore = defineStore('filters', {
  state: (): FiltersState => ({
    preSelectedFilters: {
      subjects: [],
      grades: [],
      standards: [],
      assessmentTypes: [],
      fieldTypes: [],
      tags: [],
      questions: []
    }
  }),
  getters: {
    currentPreSelectedFilters: (state) => state.preSelectedFilters,
    isValuePreSet: (state) =>
      Object.values(state.preSelectedFilters).every((value) => value.length === 0)
  },
  actions: {
    updatePreselectedFilters(preSetFilters: preSelectedFilters) {
      this.preSelectedFilters.grades = preSetFilters.grade_level_ids || []
      this.preSelectedFilters.subjects = preSetFilters.subject_ids || []
      this.preSelectedFilters.questions = preSetFilters.question_range_ids || []
      this.preSelectedFilters.assessmentTypes = preSetFilters.assessment_type_ids || []
    },
    async fetchPreSelectedFiltersIfExists() {
      try {
        const resPreselFilters = await apiService.fetchPreSelectedFilters()

        if (!resPreselFilters || resPreselFilters.status === 204 || !resPreselFilters.data) {
          return false
        }

        this.updatePreselectedFilters(resPreselFilters.data)
        return true
      } catch (error) {
        console.error('Error fetching preset-filters:', error)
        return false
      }
    },
    async clearFilters() {
      const preSetFilter: preSelectedFilters = {
        grade_level_ids: [],
        subject_ids: [],
        question_range_ids: [],
        assessment_type_ids: []
      }
      const newValue = { ...preSetFilter }
      await apiService.postPreSelectedFilters(newValue)
      this.updatePreselectedFilters(newValue)
    },
    async updatePresetFilters(filterName: string | undefined, filterIds: string[]) {
      const preSetFilter: preSelectedFilters = {
        grade_level_ids: this.currentPreSelectedFilters.grades,
        subject_ids: this.currentPreSelectedFilters.subjects,
        question_range_ids: this.currentPreSelectedFilters.questions,
        assessment_type_ids: this.currentPreSelectedFilters.assessmentTypes
      }
      const newValue = {
        ...preSetFilter,
        [getCorrectFilterName(filterName)]: filterIds
      }
      this.updatePreselectedFilters(newValue)
      await apiService.postPreSelectedFilters(newValue)
    },
    updatePreselectedTags(tags: string[]) {
      this.preSelectedFilters.tags = tags
    },
    updatePreselectedStandards(standards: string[]) {
      this.preSelectedFilters.standards = standards
    },
    updatePreselectedAssessmentTypes(assessmentTypes: string[]) {
      this.preSelectedFilters.assessmentTypes = assessmentTypes
    },
    updatePreselectedFieldTypes(fieldTypes: string[]) {
      this.preSelectedFilters.fieldTypes = fieldTypes
    }
  }
})

function getCorrectFilterName(filterName: string | undefined) {
  switch (filterName) {
    case 'Grade':
      return 'grade_level_ids'
    case 'Subject':
      return 'subject_ids'
    case 'Question':
      return 'question_range_ids'
    case 'AssessmentType':
      return 'assessment_type_ids'
    default:
      return ''
  }
}
