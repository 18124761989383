import { defineStore } from 'pinia'

export interface IModalProps {
  modalOpened: boolean
}

export interface IModalState {
  modalState: Map<string, IModalProps>
}

export const useModalStore = defineStore('modal-store', {
  state: (): IModalState => ({ modalState: new Map() }),
  getters: {
    getListOpenedModals: (state: IModalState) =>
      Array.from(state.modalState.entries())
        .filter(([, value]: [string, IModalProps]) => value.modalOpened)
        .map(([key, value]: [string, IModalProps]) => ({ key, value }))
  },
  actions: {
    openModal(uniqueKey: string, payload: IModalProps) {
      const { modalOpened } = payload
      this.modalState.set(uniqueKey, { modalOpened })
    },

    closeModal(uniqueKey: string) {
      this.modalState.delete(uniqueKey)
    },

    isModalOpen(uniqueKey: string) {
      return this.modalState.get(uniqueKey)?.modalOpened || false
    },

    getComponentFromKey(uniqueKey: string) {
      return this.modalState.get(uniqueKey) || { modalOpened: false }
    }
  }
})
