<script setup lang="ts">
import { ref, computed } from 'vue'
import IconCaretDown from './icons/IconCaretDown.vue'
import { useModalStore } from '@/stores/modal'
import LeavingModal from './modals/LeavingModal.vue'
import InstanceService from '@/services/instance.service'
import { useAssessmentPermissions } from '@/stores/assessment-permissions'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { useFoldersStore } from '@/stores/folders'
import { MOVE_TO_FOLDER_LOCATION } from '@/util/folder.constants'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { useAssessmentStore } from '@/stores/assessment'
import IconUserPlus from './icons/IconUserPlus.vue'

const featureFlagsStore = useFeatureFlagsStore()
const assessmentStore = useAssessmentStore()
const foldersStore = useFoldersStore()
const moveToFolderButtonEnabled = computed(
  () => featureFlagsStore.flags[FEATURE_FLAGS.AM_FOLDER_FUNCTIONALITY_FLAG]
)
const menu = ref(false)
const currentCallbackFunction = ref(() => {})
const props = defineProps({
  assessmentId: {
    type: Number,
    required: true
  },
  assessmentGuid: {
    type: String,
    required: true
  },
  assessmentType: {
    type: String,
    required: true
  }
})
const modalStore = useModalStore()
const assessmentPermissionsStore = useAssessmentPermissions()
const featureFlagStore = useFeatureFlagsStore()
const isEditWithinMoreBtnFlagEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.EDIT_DELETE_WITHIN_MORE_BTN]
)
const canUserShare = computed(() => assessmentPermissionsStore.canUserShare())
const canUserEdit = computed(() => assessmentPermissionsStore.canUserEdit())

const handleShareModal = () => {
  InstanceService.navigateToAssessmentShare(props.assessmentId.toString())
}

const handleEditModal = () => {
  switch (props.assessmentType) {
    case 'ibx':
      InstanceService.navigateToNewItemBank(props.assessmentId.toString())
      break
    case 'flex':
      InstanceService.navigateToFlexibleManageAssesment(props.assessmentId.toString())
      break
  }
}

const handleClickMoveToFolder = () => {
  foldersStore.setAssessmentIdsToMove([props.assessmentGuid])
  foldersStore.setMoveToFolderOrigin(MOVE_TO_FOLDER_LOCATION.ASSESSMENT_CARD)
  modalStore.openModal('move-to-folder', {
    modalOpened: true
  })
}

const showModal = (callbackFunction: () => void) => {
  currentCallbackFunction.value = () => callbackFunction()
  modalStore.openModal(`leaving-${props.assessmentGuid.toString()}`, {
    modalOpened: true
  })
}

const isIBXorFlex = (assessmentType: string) => {
  return assessmentType === 'ibx' || assessmentType === 'flex'
}

const handleFocus = () => {
  if (menu.value) {
    menu.value = false
  }
}
</script>

<template>
  <v-menu v-model="menu" location="bottom" offset="10">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="btn__more ghost-btn"
        variant="text"
        data-test="more-button"
        @focus="handleFocus"
      >
        More
        <template v-slot:append>
          <IconCaretDown />
        </template>
      </v-btn>
    </template>
    <v-list v-if="menu" class="more-button-menu" role="list">
      <v-list-item
        v-if="canUserShare"
        key="share-button"
        role="button"
        class="more-list-item"
        data-test="share-button"
        :aria-disabled="!canUserShare"
        @click="showModal(handleShareModal)"
      >
        <template v-slot:prepend>
          <IconUserPlus />
        </template>
        <v-list-item-title>Share</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="moveToFolderButtonEnabled && assessmentStore.showFullScreenButton"
        key="move-to-folder-button"
        role="button"
        class="more-list-item"
        data-test="move-to-folder-button"
        @click="handleClickMoveToFolder"
      >
        <template v-slot:prepend>
          <v-icon size="16">
            <FontAwesomeIcon :icon="['far', 'fa-folder']" />
          </v-icon>
        </template>
        <v-list-item-title>Move to Folder</v-list-item-title>
      </v-list-item>
      <v-list-item
        v-if="isIBXorFlex(props.assessmentType) && isEditWithinMoreBtnFlagEnabled && canUserEdit"
        key="edit-button"
        role="button"
        class="more-list-item"
        data-test="edit-button"
        @click="showModal(handleEditModal)"
      >
        <template v-slot:prepend>
          <v-icon size="16">
            <FontAwesomeIcon :icon="['fa', 'pen-to-square']" />
          </v-icon>
        </template>
        <v-list-item-title>Edit</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <LeavingModal
    :uniqueId="`leaving-${props.assessmentGuid.toString()}`"
    :callbackFunction="() => currentCallbackFunction()"
  />
</template>

<style scoped>
.more-button-menu {
  display: flex;
  flex-direction: column;
  & .more-list-item {
    font-family: 'Cerebri Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 17.78px;
  }
}
:deep(.v-list-item__prepend) {
  width: 30px;
  margin-left: 5px;
}

:deep(.v-list) {
  padding: 0;
}

:deep(.v-list-item-title) {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 17.78px;
  margin-right: 5px;
}

.btn__more {
  text-transform: capitalize;
  font-family: 'Cerebri Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #050f2d;
  padding: 0;
  width: 100%;
  min-width: 80px;

  & :deep(.v-btn__append) {
    margin-left: 5px;
  }
}
</style>
