<script setup lang="ts">
import { mdiPlusCircle } from '@mdi/js'
import RadioGroupModal from '@/components/modals/RadioGroupModal.vue'
import type { RadioGroupModel } from '@/types'
import InstanceService from '@/services/instance.service'
import { useModalStore } from '@/stores/modal'
import { computed, onMounted, ref } from 'vue'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'

const selectedQuestionCount = ref(1)
const modalStore = useModalStore()
const featureFlagStore = useFeatureFlagsStore()
const isSkillsAssessmentFlagEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.SKILLS_ASSESSMENT_FLAG]
)
const skillsObject = {
  label: 'Skills',
  value: 'Skills',
  description: 'Use words, numbers or images to create a one-on-one assessment.',
  callback: () => InstanceService.navigateSkillsAssessment()
}
let contentObject: RadioGroupModel[] = [
  {
    label: 'Item Bank',
    value: 'ItemBank',
    description: 'Create an assessment from our Item Bank.',
    callback: () => InstanceService.navigateToCreateItemBankAssessment()
  },
  {
    label: 'Flexible',
    value: 'Flexible',
    description: 'Create an answer key. Upload a PDF for paper or online administration.',
    callback: () => InstanceService.navigateToCreateFlexibleAssessment()
  },
  {
    label: 'On the fly',
    value: 'OnTheFly',
    description: 'Scan a bubble sheet to create an answer key.',
    callback: () => InstanceService.navigateToCreateOnTheFlyAssessment(selectedQuestionCount.value)
  }
]
const getQuestionCountOptions = () => {
  return Array.from({ length: 26 }, (_, i) => i + 1)
}

const showQuestionCount = ref(false)
const radioGroupChanged = (value: string) => {
  showQuestionCount.value = value === 'OnTheFly'
}
onMounted(() => {
  isSkillsAssessmentFlagEnabled.value && contentObject.splice(2, 0, skillsObject)
})
</script>
<template>
  <v-btn
    variant="text"
    class="create-assessment-icon-btn"
    aria-label="Create Assessment"
    data-test="create-assessment-button"
    @click="modalStore.openModal(`Create-Assement`, { modalOpened: true })"
  >
    <template v-slot:prepend>
      <v-icon color="#008272" size="22">{{ mdiPlusCircle }}</v-icon>
    </template>
    New
  </v-btn>
  <RadioGroupModal
    radioGroupLabel="Select an assessment type."
    labelButton="Create Assessment"
    :radioGroup="contentObject"
    title="Create Assessment"
    uniqueId="Create-Assement"
    @radioGroupValueSelected="radioGroupChanged($event)"
  >
    <div v-if="showQuestionCount" class="onthefly-input">
      <p class="create-assessment-button__dropdown-label">
        Select the number of questions you want on the assessment.
      </p>
      <v-select
        v-model="selectedQuestionCount"
        class="create-assessment-button__dropdown-select"
        density="compact"
        :items="getQuestionCountOptions()"
        variant="outlined"
        data-test="question-count-dropdown"
      />
    </div>
  </RadioGroupModal>
</template>
<style scoped>
.create-assessment-icon-btn {
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.78px;
  text-align: left;
  color: #202020;
  padding: 0 10px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.onthefly-input {
  display: flex;
  margin-top: 30px;
  height: 45px;
}

:deep(.v-field__field) {
  font-size: 14px !important;
}

.create-assessment-button__dropdown-label {
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 17.78px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

.create-assessment-button__dropdown-select {
  min-width: 100px;
  max-width: 100px;
}
</style>
